<template>
  <div class="elite-content_sidebar_level1_menu_wrapper">
    <ul>
      <li>
        <router-link
          :to="{ name: 'Dashboard' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path
                  d="M510-570v-270h330v270H510ZM120-450v-390h330v390H120Zm390 330v-390h330v390H510Zm-390 0v-270h330v270H120Zm60-390h210v-270H180v270Zm390 330h210v-270H570v270Zm0-450h210v-150H570v150ZM180-180h210v-150H180v150Zm210-330Zm180-120Zm0 180ZM390-330Z"
                />
              </svg>
            </span>
            <span class="text">
              {{ $t("APPS.DASHBOARD") }}
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_CRM)"
          :to="{ name: 'Crm Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path
                  d="M356-120H180q-24 0-42-18t-18-42v-176q44-5 75.5-34.5T227-463q0-43-31.5-72.5T120-570v-176q0-24 18-42t42-18h177q11-40 39.5-67t68.5-27q40 0 68.5 27t39.5 67h173q24 0 42 18t18 42v173q40 11 65.5 41.5T897-461q0 40-25.5 67T806-356v176q0 24-18 42t-42 18H570q-5-48-35.5-77.5T463-227q-41 0-71.5 29.5T356-120Zm-176-60h130q25-61 69.888-84 44.888-23 83-23T546-264q45 23 70 84h130v-235h45q20 0 33-13t13-33q0-20-13-33t-33-13h-45v-239H511v-48q0-20-13-33t-33-13q-20 0-33 13t-13 33v48H180v130q48.15 17.817 77.575 59.686Q287-514.445 287-462.777 287-412 257.5-370T180-310v130Zm329-330Z"
                />
              </svg>
            </span>
            <span class="text">
              {{ $t("APPS.CRM_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <crm-module-menu></crm-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <!-- <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_SALES)"
          :to="{ name: 'Sales Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <i class="far fa-tag"></i>
            </span>
            <span class="text">
              {{ $t("APPS.SALES_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <sales-module-menu class="sub-menu"></sales-module-menu>
            </span>
          </a>
        </router-link>
      </li> -->
      <!-- <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_PURCHASES)"
          :to="{ name: 'Purchases Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <i class="fas fa-shopping-basket"></i>
            </span>
            <span class="text">
              {{ $t("APPS.PURCHASES_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <purchases-module-menu class="sub-menu"></purchases-module-menu>
            </span>
          </a>
        </router-link>
      </li> -->
      <!-- <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_INVENTORY)"
          :to="{ name: 'Inventory Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <i class="far fa-cube"></i>
            </span>
            <span class="text">
              {{ $t("APPS.INVENTORY_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <inventory-module-menu class="sub-menu"></inventory-module-menu>
            </span>
          </a>
        </router-link>
      </li> -->
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_AUCTION)"
          :to="{ name: 'Places Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <i class="fas fa-gavel"></i>
            </span>
            <span class="text">
              {{ $t("APPS.AUCTION_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <auctions-module-menu class="sub-menu"></auctions-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <!-- <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_FILE_EXPLORER)"
          :to="{ name: 'Files Explorer' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffeb00"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path
                  d="M180-820v293-3 390-680 186-186Zm99 400h185q11-17 24-32t29-28H279v60Zm0 170h156q-3-15-4.5-30t-.5-30H279v60ZM180-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v154q-14-7-29-12t-31-8v-107H511v-186H180v680h315q20 21 44.5 36T593-80H180Zm480-110q47 0 78.5-31.5T770-300q0-47-31.5-78.5T660-410q-47 0-78.5 31.5T550-300q0 47 31.5 78.5T660-190ZM864-54 756.837-161Q736-147 711.5-138.5 687-130 660-130q-70.833 0-120.417-49.618Q490-229.235 490-300.118 490-371 539.618-420.5q49.617-49.5 120.5-49.5Q731-470 780.5-420.417 830-370.833 830-300q0 27-8.5 51.5T799-203.163L906-96l-42 42Z"
                />
              </svg>
            </span>
            <span class="text">
              {{ $t("APPS.FILE_EXPLORER_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <files-explorer-module-menu></files-explorer-module-menu>
            </span>
          </a>
        </router-link>
      </li> -->
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_RESELLER)"
          :to="{ name: 'Reseller Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffeb00"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path
                  d="M475-140q5 0 11.5-2.5T497-149l337-338q13-13 19.5-29.667Q860-533.333 860-550q0-17-6.5-34T834-614L654-794q-13-13-30-19.5t-34-6.5q-16.667 0-33.333 6.5Q540-807 527-794l-18 18 81 82q13 14 23 32.5t10 40.5q0 38-29.5 67T526-525q-25 0-41.5-7.5t-30.185-21.341L381-627 200-446q-5 5-7 10.526-2 5.527-2 11.842 0 12.632 8.5 21.132 8.5 8.5 21.167 8.5 6.333 0 11.833-3t9.5-7l138-138 42 42-137 137q-5 5-7 11t-2 12q0 12 9 21t21 9q6 0 11.5-2.5t9.5-6.5l138-138 42 42-137 137q-4 4-6.5 10.333Q361-261.333 361-255q0 12 9 21t21 9q6 0 11-2t10-7l138-138 42 42-138 138q-5 5-7 11t-2 11q0 14 8 22t22 8Zm.064 60Q442-80 416-104.5t-31-60.619Q351-170 328-193t-28-57q-34-5-56.5-28.5T216-335q-37-5-61-30t-24-60q0-17 6.724-34.049T157-489l224-224 110 110q8 8 17.333 12.5Q517.667-586 527-586q13 0 24.5-11.5t11.5-24.654q0-5.846-3.5-13.346T548-651L405-794q-13-13-30-19.5t-34-6.5q-16.667 0-33.333 6.5Q291-807 278.059-794.143L126-642q-14 14-19.5 29.5t-6.5 35q-1 19.5 7.5 38T128-506l-43 43q-20-22-32.5-53T40-579q0-30 11.5-57.5T84-685l151-151q22-22 49.793-32.5 27.794-10.5 57-10.5Q371-879 398.5-868.5T448-836l18 18 18-18q22-22 49.793-32.5 27.794-10.5 57-10.5Q620-879 647.5-868.5T697-836l179 179q22 22 33 50.033t11 57Q920-521 909-493.5T876-444L539-107q-13 13-29.532 20t-34.404 7ZM377-626Z"
                />
              </svg>
            </span>
            <span class="text">
              {{ $t("APPS.RESELLER_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <reseller-module-menu></reseller-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
          :to="{ name: 'Admin Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <i class="fas fa-dollar-sign"></i>
            </span>
            <span class="text">
              {{ $t("APPS.ADMIN_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <admin-module-menu></admin-module-menu>
            </span>
          </a>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import AdminModuleMenu from "../../../Pages/AdminModule/AdminModuleMenu.vue";
import CrmModuleMenu from "../../../Pages/CrmModule/CrmModuleMenu.vue";
import FilesExplorerModuleMenu from "../../../Pages/FilesExplorerModule/FilesExplorerModuleMenu.vue";
import InventoryModuleMenu from "../../../Pages/InventoryModule/InventoryModuleMenu.vue";
import PurchasesModuleMenu from "../../../Pages/PurchasesModule/PurchasesModuleMenu.vue";
import ResellerModuleMenu from "../../../Pages/ResellerModule/ResellerModuleMenu.vue";
import SalesModuleMenu from "../../../Pages/SalesModule/SalesModuleMenu.vue";
import AuctionsModuleMenu from "../../../Pages/AuctionsModule/AuctionsModuleMenu";

export default {
  components: {
    AdminModuleMenu,
    ResellerModuleMenu,
    FilesExplorerModuleMenu,
    InventoryModuleMenu,
    SalesModuleMenu,
    PurchasesModuleMenu,
    CrmModuleMenu,
    AuctionsModuleMenu,
  },

  props: {},

  async created() {},

  computed: {},

  data() {
    return {};
  },

  methods: {},
};
</script>
