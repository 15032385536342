export const STATUS_ACTIVE = "ACTIVE";
export const STATUS_CANCEL = "CANCEL";
export const STATUS_PENDING = "PENDING";
export const STATUS_APPROVED = "APPROVED";
export const STATUS_CONFIRMED = "CONFIRMED";
export const PROPERTY_STATUS = [
  STATUS_ACTIVE,
  STATUS_CANCEL,
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_CONFIRMED,
];

export const TYPE_COMMERCIAL = "COMMERCIAL";
export const TYPE_INDUSTRIAL = "INDUSTRIAL";
export const TYPE_VACANT_LAND = "VACANT_LAND";
export const TYPE_RESIDENTIAL = "RESIDENTIAL";
export const PROPERTY_TYPES = [
  TYPE_COMMERCIAL,
  TYPE_INDUSTRIAL,
  TYPE_VACANT_LAND,
  TYPE_RESIDENTIAL,
];
export const ORIGIN_CSS = "CSS";
export const ORIGIN_MUNICIPALITY = "MUNICIPALITY";
export const ORIGIN_CSS_MUNICIPALITY = "CSS & MUNICIPALITY";
export const PROPERTY_ORIGINS = [
  ORIGIN_CSS,
  ORIGIN_MUNICIPALITY,
  ORIGIN_CSS_MUNICIPALITY,
];

export const TAXES_MUNICIPAL = "MUNICIPAL";
export const TAXES_SCHOOL = "SCHOOL";
export const PROPERTY_TAXES = [TAXES_MUNICIPAL, TAXES_SCHOOL];

export const TRANSACTION_TYPE_PAYMENT = "PAYMENT";
export const TRANSACTION_TYPE_REFUND = "REFUND";
export const TRANSACTION_TYPES = [
  TRANSACTION_TYPE_PAYMENT,
  TRANSACTION_TYPE_REFUND,
];
