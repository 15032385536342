import {
  STATUS_ACTIVE,
  STATUS_CONFIRMED,
  STATUS_DELETED,
  STATUS_INACTIVE,
  TAXES_MUNICIPAL,
  TAXES_SCHOOL,
  TYPE_COMMERCIAL,
} from "../../constants/properties";

export default {
  PROPERTIES_LIST: "Liste des transactions",
  ADD_TRANSACTION: "Enregistrer un paiement",
  VIEW_TRANSACTION: "Voir la transaction",
  TRANSACTION_ADDED: "Transaction ajoutée",
  EDIT_TRANSACTION: "Modifier une transaction",
  TRANSACTION_UPDATED: "Transaction mise à jour",
  TRANSACTION_SAVED: "Transaction effectuée avec succès",
  DELETE_THIS_TRANSACTION: "Supprimer cette transaction ?",
  TRANSACTION_DELETED: "transaction supprimé avec succès",
  TRANSACTION_DATE: "Date de la transaction",
  TRANSACTION_TYPE: "Type de transaction",
  TRANSACTION_TYPE_PAYMENT: "Paiement",
  TRANSACTION_TYPE_REFUND: "Remboursement",
  REFUND_OPERATION: "Opération de remboursement",
  PAYMENT_OPERATION: "Enregistrement d'un paiement",
  AMOUNT: "Montant",
  REFUND_AMOUNT: "Montant à remboursé",
  AMOUNT_IS_REQUIRED: "Vuillez renseigner le montant de la transaction",
  TRANSACTION_METHODE_IS_REQUIRED:
    "Vuillez renseigner la méthode du remboursement",
  AMOUNT_IS_LESS_THAN_X:
    "Le montant saisi est inférieur au total des taxes dues ({x}). Veuillez procéder aux corrections nécessaires, s'il vous plaît.",
  TRANSACTION_METHODE: "Méthode de transaction",
};
