export default {
  DEFAULT_MODULE: "Application",
  ADMIN: "Administration",
  CRM_MODULE: "Crm",
  //SALES_MODULE: "Ventes",
  //PURCHASES_MODULE: "Achats",
  //NVENTORY_MODULE: "Inventaire",
  FILE_EXPLORER_MODULE: "Explorateur de fichiers",
  ADMIN_MODULE: "Administration",
  DASHBOARD: "Tableau de bord",
  RESELLER_MODULE: "Revendeur",
  AUCTION_MODULE: "Emplacement",
};
